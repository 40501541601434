import {Routes} from '@angular/router';
import {ImpressumComponent} from '../../legal/impressum/impressum.component';
import {DatenschutzComponent} from '../../legal/datenschutz/datenschutz.component';

export const FullLayout_ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../../authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'application',
    loadChildren: () => import('../../registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  {
    path: 'datenschutz',
    component: DatenschutzComponent,
  }];
