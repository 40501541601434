import {MbscModule} from '@mobiscroll/angular';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NZ_I18N, de_DE} from 'ng-zorro-antd/i18n';
import {DatePipe, registerLocaleData} from '@angular/common';
import de from '@angular/common/locales/de';

import {AppRoutingModule} from './app-routing.module';
import {TemplateModule} from './shared/template/template.module';
import {SharedModule} from './shared/shared.module';

import {AppComponent} from './app.component';
import {CommonLayoutComponent} from './layouts/common-layout/common-layout.component';
import {FullLayoutComponent} from './layouts/full-layout/full-layout.component';

import {NgChartjsModule} from 'ng-chartjs';
import {ThemeConstantService} from './shared/services/theme-constant.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthenticationService} from './shared/services/authentication.service';
import {AuthGuard} from './shared/guard/auth.guard';
import {ImpressumComponent} from './legal/impressum/impressum.component';
import {DatenschutzComponent} from './legal/datenschutz/datenschutz.component';
import {AdminGuard} from './shared/guard/admin.guard';
import { QRCodeModule } from 'angularx-qrcode';
import {ApplicationService} from './shared/services/applicationService';
import {SuperadminAuthenticationService} from './super-admin/services/superadmin-authentication.service';
import {SuperAdminGuard} from './shared/guard/super-admin.guard';
import {CommonLayoutFullComponent} from './layouts/common-layout-full/common-layout-full.component';
import {AppuserGuard} from './shared/guard/appuser.guard';
import {HausverwaltungLayoutComponent} from './layouts/hausverwaltung-layout/hausverwaltung-layout.component';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalService} from 'ng-zorro-antd/modal';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzIconModule} from 'ng-zorro-antd/icon';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {IconDefinition} from '@ant-design/icons-angular';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {ShareWebLayoutComponent} from './layouts/share-web-layout/share-web-layout.component';
import {ManagerGuard} from './shared/guard/manager.guard';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ErrorCatchingInterceptor} from './shared/interceptor/error-catching.interceptor';
import {SafePipeModule} from 'safe-pipe';
import {NzAlertModule} from 'ng-zorro-antd/alert';
import {OAuthModule} from 'angular-oauth2-oidc';
import {MapMarker} from './shared/components/maps/map-marker';
import { QuillModule } from 'ngx-quill';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);
import 'moment/locale/de';

registerLocaleData(de);

@NgModule({
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        CommonLayoutFullComponent,
        FullLayoutComponent,
        ImpressumComponent,
        DatenschutzComponent,
        HausverwaltungLayoutComponent,
        ShareWebLayoutComponent,
    ],
    imports: [
        MbscModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        TemplateModule,
        SharedModule,
        NgChartjsModule,
        FormsModule,
        ReactiveFormsModule,
        QRCodeModule,
        NzCardModule,
        NzIconModule.forRoot(icons),
        NzBreadCrumbModule,
        SafePipeModule,
        NzAlertModule,
        OAuthModule.forRoot(),
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    [{header: 1}, {header: 2}],
                    [{list: "ordered"}, {list: "bullet"}],
                    [{align: []}]
                ]
            }
        })
    ],
    providers: [
        {
            provide: NZ_I18N,
            useValue: de_DE
        },
        ThemeConstantService,
        AuthenticationService,
        ApplicationService,
        AuthGuard,
        AdminGuard,
        ManagerGuard,
        SuperAdminGuard,
        SuperadminAuthenticationService,
        DatePipe,
        NzMessageService,
        NzModalService,
        NzNotificationService,
        MapMarker,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorCatchingInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
