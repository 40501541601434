import {Routes} from '@angular/router';
import {AdminGuard} from '../guard/admin.guard';
import {SuperAdminGuard} from '../guard/super-admin.guard';
import {ManagerGuard} from '../guard/manager.guard';
import {PermissionsystemGuard} from "../guard/permissionsystem.guard";

import {TimestampListComponent} from '../components/timetracking/timestamp-list/timestamp-list.component';
import {UserObjectPermissionComponent} from "../components/user-object-permission/user-object-permission.component";
import {LicenseGuard} from '../guard/license.guard';
import {ObjectResidentsComponent} from "../components/object-residents/object-residents.component";
import {TimeTrackingUserListComponent} from '../components/timetracking/time-tracking-user-list/time-tracking-user-list.component';
import {ModulesGuard} from '../guard/modules.guard';
import {CounterComponent} from '../components/counter/counter.component';


export const CommonLayout_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'news',
    loadChildren: () => import('../../news/news.module').then(m => m.NewsModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'consumables',
    loadChildren: () => import('../../consumables/consumables.module').then(m => m.ConsumablesModule),
    canActivate: [
      ManagerGuard,
      LicenseGuard
    ]
  },
  {
    path: 'employee-news',
    loadChildren: () => import('../../employee-news/employee-news.module').then(m => m.EmployeeNewsModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'tickets',
    loadChildren: () => import('../../tickets/tickets.module').then(m => m.TicketsModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'servicecatalog',
    loadChildren: () => import('../../service-catalog/service-catalog.module').then(m => m.ServiceCatalogModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'contacts',
    loadChildren: () => import('../../contacts/contact.module').then(m => m.ContactModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'customers',
    loadChildren: () => import('../../customer/customer.module').then(m => m.CustomerModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'objects',
    loadChildren: () => import('../../objects/objects.module').then(m => m.ObjectsModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'worklogs',
    loadChildren: () => import('../../worklogs/worklogs.module').then(m => m.WorklogsModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'maps',
    loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule),
    canActivate: [
      LicenseGuard, ModulesGuard
    ],
    data: {module: 'MAPS'}
  },
  {
    path: 'users',
    loadChildren: () => import('../../users/users.module').then(m => m.UsersModule),
    canActivate: [
      AdminGuard
    ]
  },
  {
    path: 'counter',
    children: [
      {
        path: '',
        component: CounterComponent,
      }
    ],
    data: {
      title: 'Zähler'
    },
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'timetracking',
    children: [
      {
        path: '',
        component: TimeTrackingUserListComponent,
      },
      {
        path: ':id',
        component: TimestampListComponent,
      }
    ],
    data: {
      title: 'Zeiterfassung'
    },
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'user-object-permission',
    children: [
      {
        path: '',
        component: UserObjectPermissionComponent,
      }
    ],
    data: {
      title: 'Berechtigungssystem'
    },
    canActivate: [
      PermissionsystemGuard
    ]
  },
  {
    path: 'residents',
    children: [
      {
        path: '',
        component: ObjectResidentsComponent,
      },
    ],
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'account',
    loadChildren: () => import('../../AccountSettings/account-settings.module').then(m => m.AccountSettingsModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('../../notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'company',
    loadChildren: () => import('../../company/company.module').then(m => m.CompanyModule),
    canActivate: [
      AdminGuard
    ]
  },
  {
    path: 'hausverwaltung',
    loadChildren: () => import('../../hausverwaltung-administration/hausverwaltung.module').then(m => m.HausverwaltungModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'team',
    loadChildren: () => import('../../teams/teams.module').then(m => m.TeamsModule),
    canActivate: [
      AdminGuard,
      LicenseGuard
    ]
  }
];
