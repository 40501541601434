<div class="container-fluid h-100">
  <div class="d-flex h-100 p-v-15 flex-column justify-content-between">
    <div class="d-none d-md-flex p-h-40">

    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="col-md-4 offset-md-4">
            <img [routerLink]="'/'" style="cursor: pointer" class="img-fluid" src="assets/images/logo/logo.svg" alt="">
            <br><br>
          </div>
          <nz-card>


            <h2>Impressum</h2>
            <p>Angaben gemäß § 5 TMG:</p>
            <b>NetBrick - IT Solutions</b><br>

            Veilchenweg 14<br/> 84186 Vilsheim<br>
            <br>
            <b>Inhaber:</b>
            <p>Florian Gerhard</p>


            <b>Telefon:</b> +49 (0)8131 316464

            <br>
            <b>E-Mail:</b> info&#64;netbrick.de

            <br><br>
            <b>Umsatzsteuer-Identifikationsnummer </b><br>
            DE327053546
            <br><br>

            <p><strong>Haftung für Inhalte</strong></p>
            <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
              allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
              verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
              forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
              der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
              diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
              möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
              entfernen.</p>
            <p><strong>Haftung für Links</strong></p>
            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
              Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
              verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
              verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
              Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
              Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
            <p><strong>Urheberrecht</strong></p>
            <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
              Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
              Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
              Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
              auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
              Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
            <p> </p>

          </nz-card>
        </div>
      </div>
    </div>
    <div class="d-none d-md-flex  p-h-40 justify-content-between">
      <span class="">© {{ year }} NetBrick - IT Solutions</span>
      <ul class="list-inline">
        <li class="list-inline-item">
          <a class="text-dark text-link" [routerLink]="'/impressum'">Impressum</a>
        </li>
        <li class="list-inline-item">
          <a class="text-dark text-link" [routerLink]="'/datenschutz'">Datenschutz</a>
        </li>
      </ul>
    </div>
  </div>
</div>
