import {Routes} from '@angular/router';
import {AdminGuard} from '../guard/admin.guard';
import {SuperAdminGuard} from '../guard/super-admin.guard';
import {LicenseGuard} from '../guard/license.guard';
import {EmployeeShiftCalendarComponent} from '../components/calendars/employee-shift-calendar/employee-shift-calendar.component';
import {WasteplansComponent} from '../../wasteplans/wasteplans/wasteplans.component';
import {ManagerGuard} from "../guard/manager.guard";


export const CommonLayoutFull_ROUTES: Routes = [
  {
    path: 'tasks',
    loadChildren: () => import('../../tasks/tasks.module').then(m => m.TasksModule),
    canActivate: [
      LicenseGuard
    ]
  },
  {
    path: 'absence',
    component: EmployeeShiftCalendarComponent,
    canActivate: [
      LicenseGuard,
      ManagerGuard
    ],
  },
  {
    path: 'wasteplan',
    canActivate: [
      LicenseGuard,
      ManagerGuard
    ],
    children: [
      {
        path: '',
        component: WasteplansComponent,
      }
    ],
    data: {
      title: 'Abfuhrpläne'
    },
  },
];
