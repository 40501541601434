import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {MessageService} from "../services/message.service";

@Injectable({
  providedIn: 'root'
})
export class ManagerGuard  {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private message: MessageService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticationService.currentUserValue.isManager) {
      return true;
    } else {
      this.message.error('Dieser Bereich ist nur für Manager und Administratoren sichtbar.')
    }
    return false;
  }
}
