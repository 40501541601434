import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';

import {FullLayoutComponent} from './layouts/full-layout/full-layout.component';
import {CommonLayoutComponent} from './layouts/common-layout/common-layout.component';

import {FullLayout_ROUTES} from './shared/routes/full-layout.routes';
import {CommonLayout_ROUTES} from './shared/routes/common-layout.routes';
import {AuthGuard} from './shared/guard/auth.guard';
import {PrintLayout_ROUTES} from './shared/routes/print-layout.routes';
import {InvoiceComponent} from './shared/template/print/invoice/invoice.component';
import {CommonLayoutFull_ROUTES} from './shared/routes/common-layout-full.routes';
import {CommonLayoutFullComponent} from './layouts/common-layout-full/common-layout-full.component';
import {HausverwaltungLayoutComponent} from './layouts/hausverwaltung-layout/hausverwaltung-layout.component';
import {HausverwaltungGuard} from './shared/guard/hausverwaltung.guard';
import {HvRegistrationComponent} from './hausverwaltung/hv-registration/hv-registration.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },

  {
    path: 'hausverwaltung-application',
    component: HvRegistrationComponent
  },
  {
    path: '',
    component: CommonLayoutComponent,
    children: CommonLayout_ROUTES,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: '',
    component: CommonLayoutFullComponent,
    children: CommonLayoutFull_ROUTES,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: FullLayout_ROUTES
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: PrintLayout_ROUTES
  },
  {
    path: 'portal',
    component: FullLayoutComponent,
    loadChildren: () => import('./appuser-web/appuser-web.module').then(m => m.AppuserWebModule)
  },
  {
    path: 'hv',
    component: HausverwaltungLayoutComponent,
    loadChildren: () => import('./hausverwaltung/hausverwaltung.module').then(m => m.HausverwaltungModule),
    canActivate: [
      HausverwaltungGuard
    ]
  },
  {
    path: 'share',
    component: FullLayoutComponent,
    loadChildren: () => import('./share-web/share-web.module').then(m => m.ShareWebModule)
  },
  {
    path: 'super-admin',
    component: FullLayoutComponent,
    loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule)
  },
  {
    path: 'share/invoice/:id',
    component: InvoiceComponent
  },
  {
    path: '**',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,

      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {
}
