import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Object} from '../../../../../../../database-models';

@Component({
  selector: 'app-object-flyer',
  templateUrl: './object-flyer.component.html',
  styleUrls: ['./object-flyer.component.scss']
})
export class ObjectFlyerComponent implements OnInit {

  private objectId: string;
  public object: Object;

  constructor(
    public api: ApiService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.route
      .params
      .subscribe(params => {
        this.objectId = params['id'] || null;

        this.getObject();

      });
  }

  public getObject(): void {
    this.api.getObjectPrintData(this.objectId).subscribe((object: Object) => {
      this.object = object;
      window.print();
      window.onafterprint = function () {
        window.close();
      };
    }, onerror => {

    });
  }

  ngOnInit() {

  }

}
