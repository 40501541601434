import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Object} from '../../../../../database-models';
import {EntitiesService} from '../../shared/services/entities.service';
import {take} from 'rxjs';
import {WasteplanListComponent} from '../../shared/components/wasteplan/wasteplan-list/wasteplan-list.component';


@Component({
  selector: 'app-wasteplans',
  templateUrl: './wasteplans.component.html',
  styleUrl: './wasteplans.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class WasteplansComponent {

  @ViewChild('wasteplanListComponent') wasteplanListComponent?: WasteplanListComponent;

  public activeObject: Object;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public entities: EntitiesService
  ) {
    this.route.queryParams.subscribe(params => {
      if (!params['tab']) {
        this.router.navigate(['.'], { relativeTo: this.route, queryParams: { tab: 'calendar' }, queryParamsHandling: 'merge', skipLocationChange: true});
      }
      if (params['objectId']) {
          this.entities.objects$.pipe(take(2)).subscribe(objects => {
            const object = objects?.find(obj => obj.id === params['objectId']);
            if (object) {
              this.activeObject = object;
            }
          });
      }
    })
  }
}
