<div>
  <a style="position: absolute; right: 10px; top: 76px; z-index: 1" nz-button nzSize="small" nzType="default"
     target="_blank"
     href="https://support.netbrick.de/hc/hausmeisterapp/articles/1730105026-integration-neuer-abfuhrplane-durch-importieren-nur-weboberflache">
    <i nz-icon nzType="question" class="m-r-5"></i> Hilfe
  </a>
  <nz-tabset *minAppversion="2" nzLinkRouter class="page-header-tab m-0">

    <nz-tab>

      <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: 'calendar' }" queryParamsHandling="merge"
         (click)="wasteplanCalendarComponent?.getWastePlans()">
        <i nz-icon nzType="calendar" nzTheme="outline"></i> Kalender
      </a>


      <app-wasteplan-calendar #wasteplanCalendarComponent></app-wasteplan-calendar>


      <p class="m-10">
        <i nz-icon nzType="info-circle" nzTheme="outline"></i> Klicken Sie auf den Objektnamen um alle Termine für das
        Objekt anzuzeigen und um Termine zu importieren.
      </p>

    </nz-tab>
    <nz-tab>

      <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: 'list' }" queryParamsHandling="merge"
         (click)="wasteplanListComponent?.getWasteplanByObject()">
        <i nz-icon nzType="home" nzTheme="outline"></i> Objektbezogene Liste & Import
      </a>


      <div class="p-20">
        <app-object-customer-select [entities]="['Object']" [(reference)]="activeObject"></app-object-customer-select>
        <div *ngIf="activeObject?.id" class="m-t-20">
          <app-wasteplan-list #wasteplanListComponent [objId]="activeObject?.id"></app-wasteplan-list>
        </div>
      </div>

    </nz-tab>

    <nz-tab>

      <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ tab: 'settings' }" queryParamsHandling="merge">
        <i nz-icon nzType="setting" nzTheme="outline"></i> Globale Einstellungen
      </a>


      <div class="p-20">
        <app-wasteplan-global-settings></app-wasteplan-global-settings>
      </div>

    </nz-tab>


  </nz-tabset>


</div>
