import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HausverwaltungGuard  {

  constructor(private router: Router, private authenticationService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.currentUserSubject.pipe(map(appuser => {
      switch (appuser.user_type) {
        case 'customer':
          this.router.navigate(['portal']);
          break;
        case 'hv':
          return true;
          break;
        case 'employee':
          this.router.navigate(['dashboard']);
          break;
        default:
          this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
          break;
      }
      return false;
    }));
  }
}
