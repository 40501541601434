import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css']
})
export class DatenschutzComponent implements OnInit {

  public year = new Date().getFullYear();

  constructor() {
  }

  ngOnInit() {
  }

}
