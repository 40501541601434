import {Routes} from '@angular/router';
import {ImpressumComponent} from '../../legal/impressum/impressum.component';
import {DatenschutzComponent} from '../../legal/datenschutz/datenschutz.component';
import {ObjectFlyerComponent} from '../template/print/object-flyer/object-flyer.component';

export const PrintLayout_ROUTES: Routes = [
  {
    path: 'object-flyer/:id',
    component: ObjectFlyerComponent,
  }
];
