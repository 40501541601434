import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) {
  }

  async checkRouteBeforeInit(): Promise<void> {
    return new Promise(async (resolve) => {
      const currentUrl = window.location.toString(); // Get the current route before app loads
      console.log('check token', currentUrl);
      if (currentUrl.includes('loginToken')) {

        const params = new URLSearchParams(window.location.search);
        const loginToken = params.get('loginToken');

        if (loginToken) {
          console.log(loginToken);
          await this.authenticationService.getSessionByToken(loginToken);
          const url = new URL(window.location.href);
          url.searchParams.delete('loginToken');
          window.history.replaceState({}, '', url.toString());
          setTimeout(() => {
            resolve();
          }, 500)

        } else {
          resolve();
        }

      } else {
        resolve();
      }


    });
  }
}
